import React from "react";
import { useSelector } from "react-redux";

import DataRow from "./DataRow";

const Table = () => {
  const allBookingData = useSelector((state) => state.booking);

  return (
    <div className="table-container">
      <table className="booking-table">
        <thead className="bg-gray-100/50">
          <tr className="text-black text-left">
            <th>Destination From</th>
            <th>Destination To</th>
            <th className="text-center">Journey Date</th>
            <th className="text-center">Guests</th>
            <th className="text-center">Class</th>
            <th className="text-center">Delete</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300/20" id="lws-previewBooked">
          {allBookingData.length > 0 ? (
            allBookingData.map((bookingData, index) => (
              <DataRow bookingData={bookingData} key={index} />
            ))
          ) : (
            <tr align="center">
              <td colSpan={6} style={{ color: "#780BCD" }}>
                No data to show!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
