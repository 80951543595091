export const successToastSettings = {
    duration: 3000,
    position: "top-center",

    // Styling
    style: {
        textAlign: "center",
        color: "white",
        backgroundColor: "#780BCD"
    },
    className: "",

    // Custom Icon
    // icon: "👏",

    // Change colors of success/error/loading icon
    iconTheme: {
        primary: "#fff",
        secondary: "#780BCD",
    },

    // Aria
    ariaProps: {
        role: "status",
        "aria-live": "polite",
    },
};
export const errorToastSettings = {
    duration: 3000,
    position: "top-center",

    // Styling
    style: {
        textAlign: "center",
        color: "white",
        backgroundColor: "#780BCD"
    },
    className: "",

    // Custom Icon
    // icon: "👏",

    // Change colors of success/error/loading icon
    iconTheme: {
        primary: "#fff",
        secondary: "#FF2647",
    },

    // Aria
    ariaProps: {
        role: "status",
        "aria-live": "polite",
    },
};


/**
 * 
 * @param {Array} allData 
 * @returns Number
 */
export const getNewID = (allData) => {
    return allData.length > 0
        ? allData[allData.length - 1].id + 1
        : 1;
};