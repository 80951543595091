import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";
import totalBookingCheck from "./middlewares/totalBookingCheck";

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(totalBookingCheck))
);

export default store;