import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import Table from "./Table";
import { errorToastSettings, successToastSettings } from "../utils";
import { addBooking } from "../redux/booking/actions";
import { getNewID } from "../utils";

const Body = () => {
  const dispatch = useDispatch();
  const allBookingData = useSelector((state) => state.booking);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [journeyDate, setJourneyDate] = useState("");
  const [guestCount, setGuestCount] = useState(0);
  const [seatType, setSeatType] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleBookingSubmit = (e) => {
    e.preventDefault();
    const toastID = toast.loading("Validating data...", successToastSettings);

    const newID = getNewID(allBookingData);

    const bookingData = {
      id: newID,
      from: from,
      to: to,
      journeyDate: journeyDate,
      guestCount: guestCount,
      seatType: seatType,
    };

    const dispatchStatus = dispatch(addBooking(bookingData));

    if (dispatchStatus === undefined) {
      toast.error("Can't book more than 3 flights at once", {
        id: toastID,
        duration: 3000,
        style: {
          textAlign: "center",
          color: "white",
          backgroundColor: "#780BCD",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#FF2647",
        },
      });
      return;
    }

    setTimeout(() => {
      toast.success("Flight booked", {
        id: toastID,
        duration: 3000,
        style: {
          textAlign: "center",
          color: "white",
          backgroundColor: "#780BCD",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#780BCD",
        },
      });
    }, 1000);
  };

  useEffect(() => {
    if (from === to && (from !== "" || to !== "")) {
      toast.error(
        "Can't select same destination to and from",
        errorToastSettings
      );
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [to, from]);

  useEffect(() => {
    // check if the date is in the past
    const today = new Date();
    const selectedDate = new Date(journeyDate);
    if (selectedDate < today) {
      toast.error("Can't select a past date", errorToastSettings);
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [journeyDate]);

  // I also have the middleware implementation for this
  useEffect(() => {
    if (allBookingData.length > 2) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [allBookingData.length]);

  return (
    <section>
      <div className="mt-[160px] mx-4 md:mt-[160px] relative">
        <div className="bg-white rounded-md max-w-6xl w-full mx-auto">
          <form
            onSubmit={handleBookingSubmit}
            className="first-hero lws-inputform"
          >
            {/* From */}
            <div className="des-from">
              <p>Destination From</p>
              <div className="flex flex-row">
                <img src="./img/icons/Frame.svg" alt="" />
                <select
                  className="outline-none px-2 py-2 w-full"
                  name="from"
                  id="lws-from"
                  onChange={(e) => setFrom(e.target.value)}
                  required
                >
                  <option value="" hidden>
                    Please Select
                  </option>
                  <option>Dhaka</option>
                  <option>Dinajpur</option>
                  <option>Faridpur</option>
                  <option>Sylhet</option>
                  <option>Saidpur</option>
                  <option>Cox's Bazar</option>
                </select>
              </div>
            </div>

            {/* To */}
            <div className="des-from">
              <p>Destination To</p>
              <div className="flex flex-row">
                <img src="./img/icons/Frame.svg" alt="" />
                <select
                  className="outline-none px-2 py-2 w-full"
                  name="to"
                  id="lws-to"
                  onChange={(e) => setTo(e.target.value)}
                  required
                >
                  <option value="" hidden>
                    Please Select
                  </option>
                  <option>Dhaka</option>
                  <option>Dinajpur</option>
                  <option>Faridpur</option>
                  <option>Sylhet</option>
                  <option>Saidpur</option>
                  <option>Cox's Bazar</option>
                </select>
              </div>
            </div>

            {/* Date */}
            <div className="des-from">
              <p>Journey Date</p>
              <input
                type="date"
                className="outline-none px-2 py-2 w-full date"
                name="date"
                id="lws-date"
                onChange={(e) => setJourneyDate(e.target.value)}
                required
              />
            </div>

            {/* Guests */}
            <div className="des-from">
              <p>Guests</p>
              <div className="flex flex-row">
                <img src="./img/icons/Vector (1).svg" alt="" />
                <select
                  className="outline-none px-2 py-2 w-full"
                  name="guests"
                  id="lws-guests"
                  onChange={(e) => setGuestCount(parseInt(e.target.value))}
                  required
                >
                  <option value="" hidden>
                    Please Select
                  </option>
                  <option value="1">1 Person</option>
                  <option value="2">2 Persons</option>
                  <option value="3">3 Persons</option>
                  <option value="4">4 Persons</option>
                </select>
              </div>
            </div>

            {/* class */}
            <div className="des-from !border-r-0">
              <p>Class</p>
              <div className="flex flex-row">
                <img src="./img/icons/Vector (3).svg" alt="" />
                <select
                  className="outline-none px-2 py-2 w-full"
                  name="ticketClass"
                  id="lws-ticketClass"
                  onChange={(e) => setSeatType(e.target.value)}
                  required
                >
                  <option value="" hidden>
                    Please Select
                  </option>
                  <option>Business</option>
                  <option>Economy</option>
                </select>
              </div>
            </div>

            <button
              className={btnDisabled ? "disabledAddCity" : "addCity"}
              disabled={btnDisabled}
              type="submit"
              id="lws-addCity"
            >
              <svg
                width="15px"
                height="15px"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <span className="text-sm">Book</span>
            </button>
          </form>
        </div>
      </div>
      <Table />
    </section>
  );
};

export default Body;
