import { ADD_BOOKING, REMOVE_BOOKING } from "./actionTypes";

const bookingReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_BOOKING:
            return [...state, action.payload];

        case REMOVE_BOOKING:
            return state.filter(data => data.id !== action.payload).map(obj => ({ ...obj }));

        default:
            return state;
    }
};

export default bookingReducer;